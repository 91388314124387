<template>
  <LayoutPage />
</template>

<script>
import LayoutPage from "@/components/LayoutPage.vue";
export default {
  name: "HomeView",
  components: {
    LayoutPage,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
</style>
